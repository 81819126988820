// Shared variables
@import 'abstracts/vars';
// Brand specific variables (such as colors and fonts)
@import 'abstracts/vars-sv';

@import 'abstracts/mixins';

@import 'base/normalize';
@import 'base/boilerplate';
@import 'base/helpers';
@import 'base/links';
@import 'base/fonts-sv';
@import 'base/typography';
@import 'base/cta-btn';

@import 'global/header';
@import 'global/main-nav';
@import 'global/footer';
@import 'global/homepage-hero';
@import 'global/hero';
@import 'global/search';
@import 'global/breadcrumbs';
@import 'global/alert';
@import 'global/local-nav';
@import 'global/filters';
@import 'global/datepicker';
@import 'global/bar-nav';
@import 'global/season-toggle';
@import 'global/mountain-report-dropdown';

@import 'widgets/video';
@import 'vendor/glightbox';

@import 'widgets/rich-text';
@import 'widgets/single-img';
@import 'widgets/feature-txt';
@import 'widgets/three-img';
@import 'widgets/two-img-side-by-side';
@import 'widgets/trail-map';
@import 'widgets/accordion';
@import 'widgets/faq';
@import 'widgets/card';
@import 'widgets/custom-card';
@import 'widgets/booking';
@import 'widgets/featured-item';
@import 'widgets/booking';
@import 'widgets/interactive-map';
@import 'widgets/webcam';

@import 'page-type-components/ptc-two-col';
@import 'page-type-components/feature-media';
@import 'page-type-components/paragraph-banner-slider';
@import 'page-type-components/amenities';
@import 'page-type-components/weddings';
@import 'page-type-components/mountain-conditions';
@import 'page-type-components/dining-detail';
@import 'page-type-components/account';

@import 'vendor/glide';
@import 'vendor/lightgallery';
@import 'vendor/swiper/swiper';

.placeholder{
    background-color: $brand-primary--5;
    min-height: 150vh;
    padding: 10%;
}