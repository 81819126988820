
/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Nexa Extra Bold by Fontfabric
 * URL: https://www.myfonts.com/products/xbold-nexa-229134
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Nexa Regular by Fontfabric
 * URL: https://www.myfonts.com/products/regular-nexa-263968
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Freight Display Pro Semibold by Freight Collection
 * URL: https://www.myfonts.com/products/semibold-freight-display-pro-424264
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Freight Display Pro Book Italic by Freight Collection
 * URL: https://www.myfonts.com/products/book-italic-freight-display-pro-424261
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: Freight Display Pro Bold by Freight Collection
 * URL: https://www.myfonts.com/products/bold-freight-display-pro-424266
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */


  
@font-face {
  font-family: "NexaRegular";
  src: url('../fonts/NexaRegular/font.woff2') format('woff2'), url('../fonts/NexaRegular/font.woff') format('woff');
}
@font-face {
  font-family: "NexaExtraBold";
  src: url('../fonts/NexaExtraBold/font.woff2') format('woff2'), url('../fonts/NexaExtraBold/font.woff') format('woff');
}
@font-face {
  font-family: "FreightDisplayProBold";
  src: url('../fonts/FreightDisplayProBold/font.woff2') format('woff2'), url('../fonts/FreightDisplayProBold/font.woff') format('woff');
}
@font-face {
  font-family: "FreightDisplayProSemibold";
  src: url('../fonts/FreightDisplayProSemibold/font.woff2') format('woff2'), url('../fonts/FreightDisplayProSemibold/font.woff') format('woff');
}
@font-face {
  font-family: "FreightDisplayProBookItalic";
  src: url('../fonts/FreightDisplayProBookItalic/font.woff2') format('woff2'), url('../fonts/FreightDisplayProBookItalic/font.woff') format('woff');
}