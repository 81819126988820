//COLOR

$brand-primary: #002856;
$brand-primary--50: #8094aa;
$brand-primary--25: rgba($brand-primary, .25);
$brand-primary--5: #f2f4f7;

$brand-secondary: #a4792c;
$brand-secondary--50: #D1BC96;
$brand-secondary--5: #fbf8f5;

$brand-tertiary: #ffc629;

$gray: #d9d9d9;
$white: #fff;

//Design specific variable adjustments
// FONTS
$body-font: "NexaRegular";
$meta-font: "NexaExtraBold";
$heading-font-1: "FreightDisplayProBold";
$heading-font-2: "FreightDisplayProBold";
$h1-1: "FreightDisplayProSemibold";
$h1-2: "FreightDisplayProBookItalic";

$font-home-h1-1:"FreightDisplayProSemibold";
$font-home-h1-2:"FreightDisplayProBookItalic";
$font-h1: "FreightDisplayProBold";
$font-h2: "FreightDisplayProBold";
$font-h3: "FreightDisplayProBold";
$font-h4: "NexaExtraBold";
$font-h5: "NexaExtraBold";
$font-paragraph: "NexaRegular";
$font-alert: "NexaExtraBold";
$font-h1-color: $brand-primary;
$font-h2-color: $brand-secondary;


//FOOTER
$footer-logo-margin: -28px;

//HEADER
$header-logo-fill: $brand-primary;
$book-btn-pad-btm: 15px;

//MENU
$menu-top-link: $brand-secondary;
$menu-seasontoggle-link: $brand-secondary;
$menu-double-link: $brand-primary;
$menu-subheading-link: $brand-secondary;
$menu-subheading-link-mobile: $brand-secondary;

//SEASON TOGGLE
$toggle-tooltip-bg: $brand-primary;
$toggle-tooltip-text: #fff;
$toggle-tooltip-opacity: .5;

//BREADCRUMB
$breadcrumb-active-color: $brand-secondary;
$breadcrumb-pad-top: 6px;

//ALERT
$alert-yellow: #FFC629;
$ctrl-color: $brand-secondary;
$alert-red: #cf0000;
$alert-green: #42c557;
$alert-blue: #002856;
$alert-lblue: #97AECC;
$alert-copy-color: $alert-blue;

//VIDEO
$vid-caption-bg: $brand-secondary;

//RICH TEXT
$block-quote-color: $brand-secondary;
$bq-opacity: .25;

//FEATURE TEXT
$feature-blue: $brand-primary;
$feature-gold: $brand-secondary;
$feature-lightbg: $brand-secondary--5;
$feature-copy-dark: $brand-primary--50;
$feature-copy-gold: $white;
$feature-graphic-default: $brand-secondary;
$large-font: $font-h3;

//HOMEPAGE HERO
$hero-branding-pos: 20%;
$hero-branding-pos--lrg-scrn: 10%;
$hero-link-underline-color: $brand-primary; 
$hero-img-offset: 0px; 
$hero-link-img-radius: 0px; 
$hero-content-color: $brand-primary--50;
$hero-menu-text-color: $white;
$hero-menu-link-opacity: .2;
$menu-btn-color: $white;
$menu-btn-active-color: $brand-primary;

//LOCAL NAV
$nav-color: $brand-secondary;
$subnav-color: $brand-secondary;
$nav-dropdown-color: #F7F3EC;

//THREE IMG
$three-img-blue: $brand-primary;
$three-img-lt-blue: $brand-primary--50;


//PAGE TEMPLATE VARS
$booking-bar-bg: $brand-secondary;
$ptc-two-col-heading-color: $brand-secondary;
$ptc-two-col-cta-color: $brand-secondary;
$ptc-intro-heading-color: $brand-secondary;

//IMAGE PARAGRAPH SLIDER
$img-pg-slider-color: $brand-secondary;

//ACCORDION
$acord-heading-color: $brand-secondary;

//FEATURE MEDIA
$feature-img-slider-btn-color: $brand-secondary;
// WEDDINGS
$weddings__filter-select-background-color: $brand-secondary;
$weddings__filter-select-text-color: $brand-secondary--5;
$weddings__filter-select-option-highlight-color: lighten($weddings__filter-select-background-color, 25%);
$weddings__filter-ctrl-color: $brand-secondary;

// MOUNTAIN CONDITIONS
$mc-hero__heading-color: $brand-secondary--5;
$mc-uphill__route-color: $brand-secondary;
$mc-uphill__heading-color: $brand-primary;
$mc-uphill__ctrl-color: $brand-secondary;
$mc-lifts__h2-color: $white;
$mc-lifts__h3-color: $brand-secondary;
$mc-trails__h2-color: $brand-primary;
$mc-trails__h3-color: $mc-lifts__h3-color;
$mc-trails__btn-color: $brand-secondary;
$mc-forecast__heading-font: $font-paragraph;
$mc-forecast__btn-color: $brand-secondary;
$mc-graph__label-color: $white;
$mc__h2-color: $brand-primary;

// DINING DETAIL
$dining__color: $brand-secondary;
$dining__bg-color: $brand-secondary--5;
$dining__tag-color: $brand-secondary;
$dining__font: $font-h2;

// LOAD MORE
$load-more__color: $brand-secondary;

//CARDS
$card-secondary-color: $brand-secondary;
$vert-card-padding: 0;
$card__fw-color: $brand-secondary;
$card__reverse-heading-color: $brand-secondary;
$custom-card__slider-scrollbar-color: $brand-secondary;
$swiper__scrollbar-color: $brand-secondary;
$custom-card__meta-color: $brand-primary--50;

//FEATURE ITEM
$feature-item-text-color: $brand-secondary;

// FILTERS
$filters__color: $brand-secondary;

// WEBCAMS
$webcam__details-color: $brand-secondary;

// ACCOUT
$visit-info-bar-color: $brand-secondary--5;
$visit-info-bar-progress-color: $brand-secondary;
$account-cta-color: $brand-secondary;
$visit-info-bar-label-font: $font-h2;
$achievement-info-label-alt-color: $white;

// INTERACTIVE MAP
$map-info-cta-color: $brand-secondary;