.paragraph-banner-slider{
    margin: 60px 0;
    @media all and (min-width: $bp-lrg) {
        margin: 125px 0;
    }
}
.paragraph-banner-slider__wrapper{
    @include gutter1();
    @media all and (min-width: $bp-lrg) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }

}
.paragraph-banner-slider__media{
    flex: 0 1 50%;
    overflow: hidden;
    position: relative;
}
.paragraph-banner-slider__ctrls{
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    z-index: 2;
}
.paragraph-banner-slider__ctrl-btn{
    display: inline-block;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 25px;
    border: 1px solid #fff;
    + .feature-media__ctrl-btn{
        margin-left: 16px;
    }
    &.-prev{
        background-color: transparent;
    }
    &.-next{
        background-color: #fff;
    }
    &.glide__arrow--disabled{
        cursor: not-allowed;
    }
}

.paragraph-banner-slider__img{
    width: 100%;
}
.paragraph-banner-slider__content{
    flex: 0 1 50%;
    padding: 16px 0 0 0;
    @media all and (min-width: $bp-lrg) {
        padding: 0 7.5% 0 0;
    }
}
.paragraph-banner-slider__heading{
    color: $img-pg-slider-color;
    font-size: 32px;
}
.paragraph-banner-slider__text{
    padding-bottom: 10px;
    font-size: 14px;
}
.paragraph-banner-slider__cta-btn{
    display: block;
    font-family: $font-alert;
    font-size: 14px;
    text-align: center;
    padding: 8px 24px;
    border: 1px solid $img-pg-slider-color;
    color: $img-pg-slider-color;
    border-radius: 30px;
    background-color: $white;
    transition: all .35s ease-out;
    @media all and (min-width: $bp-lrg) {
        display: inline-block;
        min-width: 230px;
    }
    &:hover{
        color: $white;
        background-color: $img-pg-slider-color;
        text-decoration: none; 
    }
}
.paragraph-banner-slider__list-item{
    position: relative;
}
.paragraph-banner-slider__lightbox-toggle{
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 3;
}